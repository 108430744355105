import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  private fragment: string;
  url: string;

  scrollToSection(id: string) {
    this.router.navigate(['/']).then(
      ()=>  {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    )

   
  }
  
  constructor(private route: ActivatedRoute, private router: Router, location: Location,) {
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.url = location.path();
        console.log(this.url)
      } else {
        this.url = 'Home'
      }
    });
   }

  ngOnInit() {
   
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

}
