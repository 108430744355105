import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-description',
  templateUrl: './seller-description.component.html',
  styleUrls: ['./seller-description.component.scss']
})
export class SellerDescriptionComponent implements OnInit {

  content = {
    title: 'خدماتنا للبائعين',
    description: `نحن في منصة "خراج" نقدر جهودكم كـ"بائعين" ونفتخر بمساهمتكم في تنمية الموارد الغذائية من محاصيل الخضار والفواكه. نحن هنا لدعم نمو إنتاجكم في جميع مراحله، ونفتح أمامكم آفاقاً واسعة لتسويق محاصيلكم أو بضائعكم بشكل سهل واحترافي، سواء داخل منطقتكم أو في أي منطقة من مناطق المملكة.

نوفر لكم نظاماً يسمح لكم بتحديد الأسعار التي ترونها عادلة، دون تحمل أي نسبة على المبيعات، وبموثوقية عالية تضمن حقوقكم وتحقق لكم "الحرية التسويقية". مع منصة "خراج"، يمكنكم البيع من موقعكم مع توفير خدمات الدفع والتوصيل لجميع مناطق المملكة بآلية تضمن حقوقكم. انضموا إلينا واستفيدوا من تجربة تسويقية حرة وموثوقة!

    `,
    pdfLink: 'assets/pdf/بائع مشتري.pdf',
    type: 'seller'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
