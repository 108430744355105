import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private http: HttpClient,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  getAll() {
    return this.http.get(`https://nestapp.azurewebsites.net/api/v1/seo`);
  }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }
}
