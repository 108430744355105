<div class="container">
    <div class="section-title">
      
    </div>

    <div class="row">
        <div class="text-center">
            <h2>
                ستتوفر الخدمة قريبًا!
            </h2>
            <br>
            <img style="cursor: pointer;" src="assets/img/packaging.gif"  >
            <br>
            <h4>
                سنبقيكم  على اطلاع دائم بأي جديد  ، شكرًا لثقتكم
            </h4>
        </div>
     
      
    </div>
</div>