<div class="container" id="services">
  <div class="section-title">
    <h2>خدماتنا</h2>
    <div class="bar"></div>
    <p class="fs-5">
      خدمات التسويق لعمليات للبيع والشراء والنقل لقطاع تجارة الخضار والفاكهة بالجملة في المملكة.

    </p>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-credit-card"></i>
        </div>
        <h3>خدمات البيع
        </h3>
        <p class="fs-5">
          توفر "خراج" لتجار الجملة في السعودية منصة متطورة لعرض منتجاتهم بأسعار تنافسية. نحن نفتح أمامكم أبواب أكبر سوق إلكتروني لبيع الخضار والفاكهة، مما يوفر لكم الوقت والجهد في عمليات التسويق، ويضمن لكم الوصول إلى عملاء جدد بكل سهولة ويسر.

        </p>
        <button routerLink="seller-description" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </button>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-cart-plus"></i>
        </div>
        <h3>خدمات الشراء</h3>
        <p class="fs-5">
          مع "خراج" تتم عملية شراء الخضار والفاكهة بالجملة بسلاسة وموثوقية، نحن نضمن لكم خيارات متعددة وبأسعار تنافسية، مع إمكانية شراء الكميات التي تناسب احتياجاتكم مما يجعل تجربة الشراء سهلة ومريحة.

        </p>
        <button routerLink="buyer-description" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </button>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-bus"></i>
        </div>
        <h3>خدمات النقل</h3>
        <p class="fs-5">
          نحن في "خراج" لا نقدم خدمات العرض والطلب فقط، بل نرافقكم حتى وصول المنتجات إلى وجهتها. ونساهم بخدمات نقل متطورة باستخدام أحدث التقنيات للحفاظ على جودة المنتجات الزراعية أثناء الشحن، لضمان وصولها إليكم بأفضل حالة ممكنة.
        </p>
        <button
          routerLink="transporter-description"
          class="mt-3 btn btn-success"
        >
          المزيد من التفاصيل
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="section-title">
    <h2>شركاء النجاح</h2>
    <div class="bar"></div>
    <p class="fs-5">فخورين بشركاء النجاح</p>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <img
          style="width: 300px; height: 300px;"
            src="https://investorksa.net/storage/2023/04/logo.webp"
          />
        </div>
        <h3>منصة مستثمر</h3>
        <p class="fs-5">
           منصة مستثمر هي علامة تجارية
          مسجلة تحت مسمى مؤسسة منجزات للتسويق الالكتروني
        </p>
        <a href="https://investorksa.net" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </a>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <img
          style="width: 300px; height: 300px;"
            src="https://static1.s123-cdn-static-a.com/uploads/5841415/400_62552e88dc14e.jpg"
          />
        </div>
        <h3>الجمعية التعاونية الزراعية التسويقية بالشعف</h3>
        <p class="fs-5">جمعية تعاونية تعمل في مجال التسويق الزراعي</p>
        <a href="https://www.shaaf347.com/" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </a>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <img
          style="width: 300px; height: 300px;"
            src="https://asdam.my.canva.site/dark-gray-website/images/7e300d7485c64a520b1c461227dcedf9.png"
          />
        </div>
        <h3>ASDAM</h3>
        <p class="fs-5">
          معمل سعودي يزرع جميع انواع الفطر ذو جودة ولذة ويحل مشكلة استيراد الفطر
        </p>
        <a
          href="https://asdam.my.canva.site/dark-gray-website#home"
          class="mt-3 btn btn-success"
        >
          المزيد من التفاصيل
        </a>
      </div>
    </div>
  </div>
</div>
