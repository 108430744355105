import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactInfoService {

  constructor(private http: HttpClient) {}

  createContactInfo(contactInfo: any) {
    return this.http.post(`${environment.API_URL}contact-info`, contactInfo);
  }
}
