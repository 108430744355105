<app-preloader></app-preloader>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>

<div *ngIf="barAds && barAds.length > 0" class="fixed-bottom bg-dark text-white p-2 news-bar">
    <div class="container">
      <div class="news-items-wrapper">
        <div class="news-item" *ngFor="let ad of barAds">
          <b> {{ ad.title }}:</b>  {{ad.description}}
         
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="isVisible && homeAds && homeAds.length > 0" id="bannerCarousel" class="carousel slide carousel-fade fixed-bottom-left" data-bs-ride="carousel">
    <button class="btn btn-sm btn-danger hide-btn" (click)="hideCarousel()">X</button>
    <div class="carousel-inner">
    
      <div *ngFor="let ad of homeAds; let i = index" [class.active]="i === 0" class="carousel-item">
        <img *ngIf="ad.image" [src]="server + ad.image.public_url" class="d-block w-100" alt="Banner 2">
        <img *ngIf="!ad.image" [src]="'assets/img/logo.png'" class="d-block w-100" alt="Banner 2">
        <div class="carousel-caption">
            <p style="color: white;">{{ ad.title }}</p>
        </div>
    </div>
   
    </div>
  </div>