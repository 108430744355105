<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-users"></i>
                </div>
                <h3><span>200</span></h3>
                <p>مستخدمين</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-heart"></i>
                </div>
                <h3><span>190</span></h3>
                <p>عملاء راضون</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-star"></i>
                </div>
                <h3><span>250</span></h3>
                <p>تعليقات</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-download"></i>
                </div>
                <h3><span>200</span></h3>
                <p>تنزيلات التطبيق</p>
            </div>
        </div>
    </div>
</div>