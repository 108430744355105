<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="overview-content">
                <h3>
                    {{data.title}}
                </h3>
                <div class="bar"></div>
                <p>
                    {{data.description}}
                </p>
                <div class="overview-btn">
                    <a style="cursor:pointer;" (click)="downloadFile(data.pdfLink, data.type)" class="default-btn">
                        تنزيل pdf (مزيد من التفاصيل)
                        <span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="overview-image">
                <img src="assets/img/overview.png" alt="image">
            </div>
        </div>
    </div>
</div>