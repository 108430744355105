<div class="container">
    <div class="video-content">
        <h2 class="text-white"> 
          انضم إلينا اليوم وابدأ تجربة تسوق استثنائية!

        </h2>

        <h3 class="text-white">
          نحن هنا لخيارات واسعة من المحاصيل ودعمك في كل خطواتك.

        </h3>

        <h4 class="text-white">
          مع خراج، ستحظى بخيارات متعددة وأفضل الأسعار في السوق.


        </h4>

        <h5 class="text-white">
          كن جزءًا من مجتمعنا وحقق نجاح تجارتك!


        </h5>
    
        <div class="about-btn mt-5">
          <a href="https://app.kharaaj.com" target="_self"
              class="default-btn">
              تسجيل <span></span></a>
      </div>

       
    </div>

   
</div>

