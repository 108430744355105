<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>مدونة المنصة</h2>
                    <ul>
                        <li><a routerLink="/">الرئيسية</a></li>
                        <li>المدونة</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="blog-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let article of articles">
                <div class="single-blog-post">
                    <div class="image">
                        <a routerLink="/article/{{article.id}}"><img
                            style="width: 100%; height: 300px;"
                            src="{{server + article?.image?.public_url}}" alt="{{article?.title}}"></a>
                        
                    </div>

                    <div class="content">
                        <ul class="post-meta">
                            <li><i class="fa fa-calendar"></i> {{article.createdAt  | date : "fullDate"}}</li>
                    
                        </ul>
                        <h4><a routerLink="/article/{{article.id}}">
                            {{article.title}}
                        </a></h4>
                    
                        <a routerLink="/article/{{article.id}}" class="read-more">
                            قراءة المزيد
                        </a>
                    </div>
                </div>
            </div>

           

         
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>