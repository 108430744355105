
<!-- End Navbar -->
<div class="wrapper">
    <div class="container-fluid px-2 py-2 mx-1">
        <div class="row d-flex justify-content-center">
            <div
                class="container col-xl-9 col-lg-8 col-md-9 col-11 text-center
                p-2 p-md-6 rounded">
                <h1 class=" mt-2 mb-4 text-responsive-title">

                    الشروط والأحكام 
                </h1> 

  

            


                <div class="card p-4 my-4" *ngFor="let rule of rules">
                    <h4 class=" mt-2" *ngIf="rule.for == 'TRANSPORTER'">
                        الناقل
                    </h4>
                    <h4 class=" mt-2" *ngIf="rule.for == 'BUYER_SELLER'">
                        البائع مشتري
                    </h4>
                    <h4 class=" mt-2" *ngIf="rule.for == 'BUYER'">
                        المشتري
                    </h4>
                    <h4 class=" mt-2" *ngIf="rule.for == 'SELLER'">
                        البائع
                    </h4>
                    <hr>

                    <div class="row fs-18" >
                        <div
                            class="text-right fs-22 " style=" text-align: justify;
                            text-justify: inter-word;"

                            [innerHTML]="rule?.text"></div>
                    </div>

                </div>
                <div class="default-shape">
                    <div class="shape-1"><img src="assets/img/shape/1.png"
                            alt="image"></div>
                    <div class="shape-2 rotateme"><img
                            src="assets/img/shape/2.png" alt="image"></div>
                    <div class="shape-3"><img src="assets/img/shape/3.svg"
                            alt="image"></div>
                    <div class="shape-4"><img src="assets/img/shape/4.svg"
                            alt="image"></div>
                    <div class="shape-5"><img src="assets/img/shape/5.png"
                            alt="image"></div>
                </div>
            </div>
        </div>
    </div>

</div>