<div class="container" id="about">
    <div class="section-title">
        <h2>عن خراج</h2>
        <div class="bar"></div>
    </div>

    <p class="fs-5 w-100">
        خراج هي منصة إلكترونية مبتكرة تُعد الأولى من نوعها في المنطقة بشكل احترافي، وتُشكل وسيطًا تجاريًا رائدًا في مجال تجارة الخضار والفاكهة في السعودية بالجملة. وتقدم خراج لعملائها تجربة سلسة لشراء وبيع المنتجات الزراعية بالجملة، مع تعدد الخيارات وأفضل الأسعار. كما تساهم المنصة في توصيل الطلبات إلى جميع أنحاء المملكة في أقل من يوم، مما يسهم في تعزيز الكفاءة في السوق. تتماشى رؤية خراج وأهدافها مع رؤية المملكة 2030، حيث تسعى إلى توفير فرص حقيقية لتحقيق النجاح في اقتصاد مستدام ومزدهر.
    </p>

    <div class="mt-4 overview-btn mx-auto section-title">
        <a style="cursor:pointer;" (click)="downloadFile()" class="default-btn">
            تنزيل pdf (مزيد من التفاصيل) <span></span>
        </a>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>قليلًا عنا</h3>
                <div class="bar"></div>
                
                <p class="fs-5">
                    نتطلع في منصة خراج إلى تحول جذري في سوق تجارة الخضار والفاكهة بالجملة في السعودية، حيث نقدم حلولًا مبتكرة تستجيب للتحديات المتزايدة في هذا القطاع. بفضل مساهمتها في خدماتها اللوجستية المتطورة، توفر "خراج" نظامًا إلكترونيًا موثوقًا وسهل الاستخدام، يعرض أسعار المنتجات بشكل يومي، مما يسهل على العملاء عمليات البيع والشراء بخيارات متعددة. تعتمد المنصة على إجراءات دقيقة لتسجيل المستخدمين من بائعين ومشترين وناقلين، من خلال تقديم الوثائق الرسمية وأرقام الحسابات البنكية، مما يضمن تجربة تسوق قانونية وآمنة. انضم إلينا اليوم وكن جزءًا من نجاح مستدام.
                </p>

                <div class="about-btn">
                    <a href="https://app.kharaaj.com" target="_self" class="default-btn">
                        نسخة الويب <span></span>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/about.webp" alt="About Kharaaj Image">
            </div>
        </div>
    </div>
</div>
