<div class="container" id="contact">
    <div class="section-title">
        <h2>
            اتصل بنا
        </h2>
        <div class="bar"></div>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="contact-form">
                <form  [formGroup]="contactForm" (ngSubmit)="onFormSubmit()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" formControlName="fullName" id="name" class="form-control" required placeholder="الاسم بالكامل">
                                <label *ngIf="contactForm.get('fullName').invalid && contactForm.get('fullName').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" formControlName="email" class="form-control" required placeholder="yourmail@mail.com">
                                <label *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched" [ngClass] = "'error'"> يرجى التحقق من البريد الإلكتروني</label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group" dir="ltr">
                                <input type="text" name="phone_number" id="phone_number" formControlName="phone" required class="form-control" placeholder="+966XXXXXXXXX">
                                 <label *ngIf="contactForm.get('phone').invalid && contactForm.get('phone').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" formControlName="subject" class="form-control" required placeholder="الموضوع">
                                <label *ngIf="contactForm.get('subject').invalid && contactForm.get('subject').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" formControlName="content" cols="30" rows="6" required placeholder="الرسالة"></textarea>
                                <label *ngIf="contactForm.get('content').invalid && contactForm.get('content').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="default-btn">
                                    إرسال <span></span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>
                        تواصل معنا عبر رقم هاتفك أو عنوان بريدك الإلكتروني
                    </h3>
                    <h2>
                        <a dir="ltr" href="tel:+966596677065">+966596677065</a>
                        <span>أو</span>
                        <a href="mailto:hello@apzie.com">info@kharaaj.com</a>
                        <hr>
                        <a>
                            جده – حي الرحمانية شارع الفروسية  
                        </a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://www.youtube.com/channel/UCHKg5f08j0G2xhMjQQdpySA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://twitter.com/kharaajsa" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.snapchat.com/add/kharaajsa" target="_blank"><i class="fab fa-snapchat"></i></a></li>
                        <li><a href="https://www.instagram.com/kharaajsa/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
