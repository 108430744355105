<div class="container" id="faq">
    <div class="section-title">
        <h2>
            الأسئلة المتداولة
        </h2>
        <div class="bar"></div>

    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                ما هي رسوم منصة خراج؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseOne" class="collapse show" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                               <p>
                                من سياسة منصة خراج تخفيض الرسوم حيث لا يوجد رسوم على المزارع الحرفية والجمعيات الزراعية مساندة لهم .
ويمتد (حاليا ) عدم وجود رسوم على المؤسسات والشركات ..
تقتصر الرسوم العادلة على المشترين والناقلين فقط
                               </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                هل من الممكن أن يكون لديك حساب واحد كمشتري وبائع؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseTwo" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    لا تحتاج إلى إنشاء حسابين لكل نوع ، يمكنك إنشاء حساب كمشتري - بائع والحصول على كلتا الخدمتين اذا كان نشاطكم تسويقي حسب المستندات الرسمية.  
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                هل ممكن للمستفيدين رفع شكوى عند وجود اي خطأ؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseThree" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    توفر منصتنا الأمان لجميع عمليات المعاملات بدءًا من البيع والشراء والشحن.
يقوم المراقبون الميدانيون لدينا بالتحقق من شكوى و السعي إلى حلها. 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFour">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                ماهي الضمانات للمشتري والبائع والناقل ؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseFour" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    منصة خراج الاحترافية تضع شروط واحكام تواكب آلياتها لضمان المعاملات لجميع المستفيدين
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFive">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                ما هي مدة التحقق من المعاملات؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseFive" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    تستغرق عملية الفحص في حدود 24 ساعة على المخالف  للتحقق من شكاوى المستخدمين. بعد الشكوى ، يتم تحويل الأموال من المشتري إلى البائع أو إلغاء العملية  و تحميل غرامة مالية على المتجاوز.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingSix">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                هل يمكن الدخول باكثر من هاتف اوبريد الكتروني لأكثر من حساب  ؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseSix" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    جميع الحسابات لدينا نظامية مقيدة برقم جوال واحد وبريد إلكتروني واحد فقط
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingSeven">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                ما هي المتطلبات للتسجيل؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseSeven" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                
                                    <table class="table table-striped">
                                 
                                        <tbody>
                                          <tr>
                                            <th scope="row">البائع</th>
                                            <td>
                                                <p>
                                                    سجل تجاري - سجل زراعي أو و ثيقة عمل حر - شهادة الزكاة - الرقم الضريبي (في حال وجوده) - حساب بنكي مطابق للمستندات و رقم الجوال

                                                </p>
                                            </td>
                                        
                                          </tr>
                                          <tr>
                                            <th scope="row">المشتري</th>
                                            <td>
                                                <p>
                                                    حساب مطابق للمستندات - وثيقة عمل حر أو تجاري في حال إذا كان المسجل مؤسسة و رقم الجوال
                                                </p>
                                            </td>
                                            
                                          </tr>
                                          <tr>
                                            <th scope="row">الناقل</th>
                                            <td>
                                                <p>
                                                    رخصة قيادة سارية - ترخيص من الهيئة العامة للنقل - التراخيص اللازمة المعمول بها في المملكة العربية السعودية
                                                </p>
                                            </td>
                                         
                                          </tr>
                                        </tbody>
                                      </table>
                                      <hr>
                                   <p>
                                    بالنسبة في حال تسجيل المستفيد (بائع/مشتري) تنطبق عليه المتطلبات اللازمة لدى كل من المشتري و البائع
                                   </p>
                                   
                                   
                                    
                                  
                                
                                   
                                
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading8">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                ماهي ساعات العمل في منصة خراج؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse8" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    يفتح السوق ٢.٠٠ فجرا 
                                    ويغلق الساعة ١٠.٠٠ صباحا
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading9">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                متى يمكنني تنزيل عروض منتجاتي؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse9" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <p>
                                    يمكنكم تنزيل العروض من الساعة ١.٠٠ صباحا  الى ١٢.٠٠ منتصف الليل
                                    وبعدها يتم مراجعة العروض وبعد قبولها تظهر في السوق الساعة ٢.٠٠ فجرا
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading10">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                ماذا احتاج من بيانات لتنزل العروض؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse10" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                               <p>
                                الاحتياج الى ٣ صور واضحة ومقطع ٢٠ ثانية وتسجيل صوتي 
وكتابة معلومات عن المنتج
                               </p>
                            </div>
                        </div>
                    </div>

                

                 

               

               

                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                   
                    <div class="card">
                        <div class="card-header" id="heading11">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                متى يمكن سحب الأموال الخاصة بالمستفيدين؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse11" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                               <p>
                                بعد طلبها التعامل مع تحويلها خلال ٣ ايام عمل 
                               </p>
                            </div>
                        </div>
                    </div>
                 
                 

                    <div class="card">
                        <div class="card-header" id="heading13">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                متى يمكن اضافة اموال؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse13" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                               <p>
                                يمكن اضافتها خلال يوم عمل من وقت نزولها في حساباتنا الرسمية
                               </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading14">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                هل يوجد مميزات للمستفيدين؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse14" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                               <p>
                                منصة خراج اتت دعم للسوق الزراعي بكافة الانشطة  ..
                                ولديها رؤية واعدة بتقديم كل ما يعود بالنفع على الجميع
                               </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading15">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                ماذا تفعل منصة خراج للمخالفين؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse15" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                             <p>
                                خراج تسعى لسوق زراعي نموذجي، وفي حال التحقق من وجود اي مخالفة للشروط والاحكام يتم المفاهمة وفي حال التكرار يتم ايقاف التعامل مع المستفيد  .
                             </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading16">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                هل منصة خراج تبيع او تشتري؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse16" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                             <p>
                                منصة خراج فقط تعتبر وسيط الكتروني يقدم تسويق للمنتجات من البائعين للمسوقين مع اتاحة الفرصة للنقل كخدمات مساندة.
                             </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading17">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                هل هناك تعاملات مالية نقدا  ؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse17" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                             <p>
                                لا تتعامل منصة خراج بالنقد، فقط بالتعاملات المالية الرسمية لضمان الحقوق.
                             </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading18">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                هل طلب وسيلة نقل إلزامية من قبل منصة خراج ؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse18" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                             <p>
                                خراج تقدم خدمة النقل اختياري 
                                وليست الزامي عليها او على المشتري
                             </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading19">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                                هل ممكن التعديل على المنتجات بعد طرحها في السوق؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse19" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                            <p>
                                لا يمكن التعديل عليها، فقط يمكن ايقاف عرضها
                            </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading20">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                                هل يوجد عقد واتفاق مع منصة خراج  ؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse20" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                            <p>
                                العقد هو الموافقة على الشروط والاحكام الموجودة في المنصة 
                                وفي حال وجود اي تحديث يصل اشعار للموافقه عليه قبل استخدام المنصة
                            </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading21">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                ماهي شراكات التي ترحب بها منصة خراج ؟  
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse21" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                          <p>
                            منصة خراج ترحب باي شراكات في نطاق السوق الزراعي بكافة اشكاله  .. نرحب بدخولكم على <a routerLink="join-us">الرابط</a>
                          </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="heading22">
                            <button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                ما هو نشاط عمل منصة خراج وحدودها الجغرافية؟
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapse22" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                          <p>
                            منصة خراج تعمل على منتجات السوق الزراعي بيع وشراء ونقل( جمله ) B2B
وتسعى لتغطية جميع مناطق السعودية
                          </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

       
    </div>
</div>