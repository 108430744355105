import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementsService {

  constructor(private http: HttpClient) { }

  getHomeList() {
    return this.http.get(`https://nestapp.azurewebsites.net/api/v1/advertisements/published?type=HOME`);
  }

  getBarList() {
    return this.http.get(`https://nestapp.azurewebsites.net/api/v1/advertisements/published?type=BAR`);
  }

}
