import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-description',
  templateUrl: './buyer-description.component.html',
  styleUrls: ['./buyer-description.component.scss']
})
export class BuyerDescriptionComponent implements OnInit {

  content = {
    title: 'خدماتنا للمشترين',
    description: `"كمشترين" تهتم منصتنا "خراج" بكم كأداة من أدوات نماء الاقتصاد المحلي متمثلة في تخصصكم بأسواق الخضار والفواكه حيث سخرنا خدمات منصتنا بتسهيل لكم عمليات الشراء وإتاحة جميع الخيارات بعرض المنتوجات وإتاحتها بجميع تفاصيلها وأسعارها بشكل سهل واحترافي سواء داخل منطقتكم أو في أي منطقة من مناطق المملكة بالسعر الذي ترونه يحقق رغبتكم بنسبة بسيطة من قيمة المبيعات وذلك بموثوقية كبيرة تضمن حقوقكم وتحقق لكم "الحرية الشرائية" بحيث وفرت منصتنا عملية الشراء من موقعكم والدفع والتوصيل لجميع مناطق المملكة بآليه تضمن حقوقكم .`,
    pdfLink: 'assets/pdf/بائع مشتري.pdf',
    type: 'buyer'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
