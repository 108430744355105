import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ContactInfoService } from 'src/app/services/contact-info.service';
import { SeoService } from 'src/app/services/seo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

  userForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email]),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    activity: new FormControl('', Validators.required),
    message: new FormControl('')
  });

  constructor(private contactInfoService: ContactInfoService, private toastr: ToastrService,
    private seoService: SeoService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  validatePhone(phone: string) {
    let regex = new RegExp(/(?:\+?0*?966)?0?5[0-9]{8}/);
    return regex.test(phone);
  }

  onFormSubmit() {
    const data = this.userForm.getRawValue();
    if (this.validatePhone(data.phone) == false) return Swal.fire('يرجى التحقق من رقم الهاتف', '', 'warning');
    if (data.email.length == 0) delete data.email;

    if (data.message == '' || data.message == null) delete data.message;
    this.contactInfoService.createContactInfo(data).subscribe(
      res => {
        this.userForm.reset();
        this.toastr.success('لقد تم تسجيلك بنجاح', 'انتظر مكالمة منا!')
        Swal.fire('لقد تم تسجيلك بنجاح', 'انتظر مكالمة منا!', 'success');
      }, error => {


        if (error.status == 400)
          Swal.fire('الرجاء التثبت من المعطيات', '', 'error');


        else if (error.status == 500)
          Swal.fire('حصل خطأ', 'يرجى المحاولة مرة أخرى', 'error');


        else

          Swal.fire(error?.error?.message, '', 'error');
      }
    )
  }

  ngOnInit(): void {
    const script = document.getElementById('structuredDataScript');
    if (script) {
      script.remove();
    }

    if (this.seoService.isServer()) {
      this.seoService.getAll().subscribe((res: any) => {
        const config = res.data;
        // Title and Description
        this.titleService.setTitle(config.title);
        this.metaService.updateTag({
          name: 'description',
          content: config.description,
        });

        // Canonical
        const link: HTMLLinkElement = this.createLinkElement();
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', config.canonical);
        document.head.appendChild(link);

        // Robots
        this.metaService.updateTag({ name: 'robots', content: config.robots });

        // KeyWords
        this.metaService.updateTag({ name: 'keywords', content: config.keywords });

        // Open Graph
        this.metaService.updateTag({
          property: 'og:title',
          content: config.og.title,
        });
        this.metaService.updateTag({
          property: 'og:description',
          content: config.og.description,
        });
        this.metaService.updateTag({
          property: 'og:image',
          content: config.og.image,
        });
        this.metaService.updateTag({
          property: 'og:url',
          content: config.og.url,
        });
        this.metaService.updateTag({
          property: 'og:type',
          content: config.og.type,
        });
        this.metaService.updateTag({
          property: 'og:site_name',
          content: config.og.site_name,
        });
        this.metaService.updateTag({
          property: 'og:locale',
          content: config.og.locale,
        });

        // Twitter Card
        this.metaService.updateTag({
          name: 'twitter:card',
          content: config.twitter.card,
        });
        this.metaService.updateTag({
          name: 'twitter:title',
          content: config.twitter.title,
        });
        this.metaService.updateTag({
          name: 'twitter:description',
          content: config.twitter.description,
        });
        this.metaService.updateTag({
          name: 'twitter:image',
          content: config.twitter.image,
        });
        this.metaService.updateTag({
          name: 'twitter:site',
          content: config.twitter.site,
        });
        this.metaService.updateTag({
          name: 'twitter:creator',
          content: config.twitter.creator,
        });

        // Structured Data
        const script: HTMLScriptElement = this.createScriptElement();
        script.type = 'application/ld+json';
        script.text = JSON.stringify(config.structuredData);
        document.head.appendChild(script);
      });
    }

  }

  private createLinkElement(): HTMLLinkElement {
    const link: HTMLLinkElement = document.createElement('link');
    return link;
  }

  private createScriptElement(): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    return script;
  }
}



