<!-- End Navbar -->
<div class="wrapper">
  <div class="container-fluid px-2 py-2 mx-1">
    <div class="row d-flex justify-content-center">
      <div
        class="container col-xl-9 col-lg-8 col-md-9 col-11 text-center p-2
        p-md-6 rounded">
        <h1 class=" mt-2 text-responsive-title">الآن .. فرص حقيقية في أسواقنا
          الزراعية</h1>
        <p class="text-center mb-4 px-md-4 text-responsive">
          منصة خراج أول منصة من نوعها لتجارة الفواكه والخضار بالجملة صادره عن
          شركة سعودية / شركة انجم لتقنية المعلومات.
        </p>



        <div class="card p-4">
          <h2 class=" mt-2">
            أدخل معلومات الاتصال بك وانضم إلينا
          </h2>
          <hr>
          <form class="form-card" [formGroup]="userForm"
            (ngSubmit)="onFormSubmit()">
            <div class="row j text-right">
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label>الاسم <span class="text-danger"> *</span></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  id="fname"
                  name="fname"
                  placeholder="أدخل اسمك "

                  />
                <label *ngIf="userForm.get('firstName').invalid &&
                  userForm.get('firstName').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">اللقب<span
                    class="text-danger"> *</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="lname"
                  formControlName="lastName"
                  name="lname"
                  placeholder="أدخل اسمك الأخير"

                  />
                <label *ngIf="userForm.get('lastName').invalid &&
                  userForm.get('lastName').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">البريد الإلكتروني</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  name="email"
                  placeholder="البريد الإلكتروني"

                  />
                <label *ngIf="userForm.get('email').invalid &&
                  userForm.get('email').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">رقم الهاتف<span
                    class="text-danger"> *</span></label>
                <input
                  type="text"
                  dir="ltr"
                  class="form-control"
                  id="mob"
                  formControlName="phone"
                  name="mob"
                  placeholder="رقم الهاتف"

                  />
                <label *ngIf="userForm.get('phone').invalid &&
                  userForm.get('phone').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">المنطقة<span
                    class="text-danger"> *</span></label>

                <select formControlName="state" class="form-control mx-0">
                  <option>الرياض</option>
                  <option>مكـة المكرمة</option>
                  <option>المدينة المنورة</option>
                  <option>القصيم</option>
                  <option>المنطقة الشرقية</option>
                  <option>عسير</option>

                  <option>تبــوك</option>

                  <option>حائل</option>

                  <option>الحدود الشمالية</option>

                  <option>جازان</option>

                  <option>نجران</option>

                  <option>نجران</option>

                  <option>الباحة</option>

                  <option>الجـوف</option>
                </select>
                <label *ngIf="userForm.get('state').invalid &&
                  userForm.get('state').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">
                  المدينة
                  <span class="text-danger"> *</span></label>
                <input
                  formControlName="city"
                  type="text"
                  class="form-control"
                  id="mob"
                  name="mob"
                  placeholder="المدينة"

                  />
                <label *ngIf="userForm.get('city').invalid &&
                  userForm.get('city').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">الجنس<span
                    class="text-danger"> *</span></label>

                <select formControlName="gender" class="form-control mx-0">
                  <option>ذكر</option>
                  <option>أنثى</option>
                </select>
                <label *ngIf="userForm.get('gender').invalid &&
                  userForm.get('gender').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>

              <div class="form-group col-sm-6 mb-4 flex-column d-flex
                align-items-start">
                <label class="form-control-label">النشاط<span
                    class="text-danger"> *</span></label>

                <select formControlName="activity" class="form-control mx-0">
                  <option>نقليات (فرد)</option>
                  <option>نقليات (مؤسسة)</option>
                  <option>نقليات (شركة)</option>
                  <option>بائع تجاري (فرد)</option>
                  <option>بائع (مؤسسة)</option>
                  <option>بائع (شركة)</option>
                  <option>مالك مزرعة</option>
                  <option>مشتري تجاري (فرد)</option>
                  <option>مشتري (مؤسسة)</option>
                  <option>مشتري (شركة)</option>
                  <option>مسوق</option>
                </select>
                <label *ngIf="userForm.get('activity').invalid &&
                  userForm.get('activity').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
            </div>  
            <div class="row justify-content-between text-right">
              <div class="form-group col-12 flex-column d-flex
                align-items-start">
                <label class="form-control-label">
                  فضلا كتابة كود التسويق
               </label>
                <textarea
                  formControlName="message"
                  type="text"
                  class="form-control"
               
                  id="ans"
                  name="ans"
                  placeholder=""></textarea>
                <label *ngIf="userForm.get('message').invalid &&
                  userForm.get('message').touched" [ngClass]="'error'"> الرجاء
                  إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-end">

            </div>

            <div class="mt-4 overview-btn">

              <div class="form-group">
                <button style="cursor:pointer;" *ngIf="userForm.valid"
                  type="submit" class="default-btn">
                  تسجيل المعلومات
                </button>
                <button style="cursor:pointer;" *ngIf="userForm.invalid"
                  disabled type="submit" class="btn btn-success">
                  تسجيل المعلومات
                </button>
              </div>
            </div>

          </form>
        </div>
        <div class="default-shape">
          <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
          <div class="shape-2 rotateme"><img src="assets/img/shape/2.png"
              alt="image"></div>
          <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
          <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
          <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
        </div>
      </div>
    </div>
  </div>

</div>