import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor(private seoService: SeoService,
    private titleService: Title,
    private metaService: Meta,) { }

  ngOnInit(): void {
    
    this.metaService.removeTag('description');
    if (this.seoService.isServer()) {
      this.seoService.getAll().subscribe((res: any) => {
        const config = res.data;
        // Title and Description
        this.titleService.setTitle(config.title);
        this.metaService.updateTag({
          name: 'description',
          content: config.description,
        });

        // Canonical
        const link: HTMLLinkElement = this.createLinkElement();
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', config.canonical);
        document.head.appendChild(link);

        // Robots
        this.metaService.updateTag({ name: 'robots', content: config.robots });

        // KeyWords
        this.metaService.updateTag({ name: 'keywords', content: config.keywords });

        // Open Graph
        this.metaService.updateTag({
          property: 'og:title',
          content: config.og.title,
        });
        this.metaService.updateTag({
          property: 'og:description',
          content: config.og.description,
        });
        this.metaService.updateTag({
          property: 'og:image',
          content: config.og.image,
        });
        this.metaService.updateTag({
          property: 'og:url',
          content: config.og.url,
        });
        this.metaService.updateTag({
          property: 'og:type',
          content: config.og.type,
        });
        this.metaService.updateTag({
          property: 'og:site_name',
          content: config.og.site_name,
        });
        this.metaService.updateTag({
          property: 'og:locale',
          content: config.og.locale,
        });

        // Twitter Card
        this.metaService.updateTag({
          name: 'twitter:card',
          content: config.twitter.card,
        });
        this.metaService.updateTag({
          name: 'twitter:title',
          content: config.twitter.title,
        });
        this.metaService.updateTag({
          name: 'twitter:description',
          content: config.twitter.description,
        });
        this.metaService.updateTag({
          name: 'twitter:image',
          content: config.twitter.image,
        });
        this.metaService.updateTag({
          name: 'twitter:site',
          content: config.twitter.site,
        });
        this.metaService.updateTag({
          name: 'twitter:creator',
          content: config.twitter.creator,
        });

        // Structured Data
        const script: HTMLScriptElement = this.createScriptElement();
        script.type = 'application/ld+json';
        script.text = JSON.stringify(config.structuredData);
        document.head.appendChild(script);
      });
    }
  }
  private createLinkElement(): HTMLLinkElement {
    const link: HTMLLinkElement = document.createElement('link');
    return link;
  }

  private createScriptElement(): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    return script;
  }
}
