<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Kharaaj منصة خراج</h1>
                            <p class="fs-4">الوجهة الأولى تسويق المنتجات الزراعية في المنطقة
                            </p>

                            <p class="fs-4">
                                تُعتبر منصة خراج Kharaaj أول منصة إلكترونية متخصصة في تقديم خدمات مبتكرة لتسهيل بيع وشراء ونقل المنتجات الزراعية بالجملة.

                            </p>
                            <div class="banner-holder">
                                <a routerLink="/"><img src="assets/img/store/1.png" alt="Store Image 1"></a>
                                <a routerLink="/"><img src="assets/img/store/2.png" alt="Store Image 2"></a>
                            </div>
                            <div class="about-btn mt-5 mb-2">
                                <a href="https://app.kharaaj.com" target="_self" class="default-btn fs-2">نسخة الويب
                                    <span></span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="video-player">
                            <video autoplay controls width="100%">
                                <source src="assets/video/kharaaj.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="Shape Image 1"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="Shape Image 2"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="Shape Image 3"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="Shape Image 4"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="Shape Image 5"></div>
    </div>
</div>

<section class="fun-facts-area pb-70">
    <app-how-it-works></app-how-it-works>
</section>

<section id="about" class="about-area pb-100">
    <app-about></app-about>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="Shape Image 1"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="Shape Image 2"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="Shape Image 3"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="Shape Image 4"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="Shape Image 5"></div>
    </div>
</section>

<section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section>

<section id="features" class="features-area pb-70">
    <app-features></app-features>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="Shape Image 1"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="Shape Image 2"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="Shape Image 3"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="Shape Image 4"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="Shape Image 5"></div>
    </div>
</section>

<section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="Shape Image 1"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="Shape Image 2"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="Shape Image 3"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="Shape Image 4"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="Shape Image 5"></div>
    </div>
</section>

<section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="Shape Image 1"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="Shape Image 2"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="Shape Image 3"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="Shape Image 4"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="Shape Image 5"></div>
    </div>
</section>
