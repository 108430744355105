<div  class="main-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <h1>Apzie Makes Your Life Very Easier</h1>
                    <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>
                    <form class="banner-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your Email">
                        <button type="submit">Begin Free Trial</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js"></div>
</div>

<div class="video-area pt-100">
    <app-how-it-works></app-how-it-works>
</div>

<section id="about" class="about-area ptb-100">
    <app-about></app-about>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section id="features" class="features-area pb-70">
    <app-features></app-features>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<app-app-screenshots></app-app-screenshots>

<section class="testimonials-area ptb-100">
    <app-testimonials></app-testimonials>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <app-overview></app-overview>
</section>

<section class="pricing-area pt-100 pb-70">
    <app-pricing></app-pricing>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section>

<app-app-download></app-app-download>

<section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section>

<section id="blog" class="blog-area pt-100 pb-70">
    <app-blog></app-blog>
</section>

<section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<app-subscribe></app-subscribe>