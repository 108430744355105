import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ContactMessageService } from '../../../services/contact-message.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    fullName: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email, Validators.required]),
    subject: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
  });

  constructor(
    private contactMessageService: ContactMessageService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  validatePhone(phone: string) {
    let regex = new RegExp(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
    return regex.test(phone);
  }
  onFormSubmit() {
    const data = this.contactForm.getRawValue();
    if(this.validatePhone(data.phone) == false) return Swal.fire('يرجى التحقق من رقم الهاتف', '', 'warning');
    this.contactMessageService.createContactMessage(data).subscribe(
      res => {
        this.toastr.success('لقد تلقينا رسالتك', 'انتظر مكالمة منا!')
        Swal.fire('تم الارسال!', 'شكرا لتواصلك معنا', 'success');
      }, err => {
        Swal.fire('الرجاء التثبت من المعطيات', '', 'error');
      }
    )
  }

}
