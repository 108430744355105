import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  @ViewChild("my_video_1", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  width =window.innerWidth;
  constructor() {}
  year = new Date().getFullYear()
  ngOnInit(): void {
    console.log(this.isPlay)
    this.pause()
  }

  play() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.play();
  }

  pause() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.pause();
  }

}
