import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { RulesService } from '../../../services/rules.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  rules: any;

  constructor(private rulesService: RulesService) { }

  ngOnInit(): void {
    this.getRules()
  }

  getRules() {
    this.rulesService.getAll()
    .pipe(take(1))
    .subscribe((response: any) => {
      if (response && response?.data) {
        this.rules = response?.data;
      
      }
    })
  }

}
