import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  downloadFile() {
    let link = document.createElement("a");
    link.download = 'kharaaj';
    link.href = 'assets/pdf/kharaaj.pdf';
    link.click();
  }

}
