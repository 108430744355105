import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { SellerDescriptionComponent } from './components/pages/seller-description/seller-description.component';
import { BuyerDescriptionComponent } from './components/pages/buyer-description/buyer-description.component';
import { TransporterDescriptionComponent } from './components/pages/transporter-description/transporter-description.component';
import { JoinUsComponent } from './components/pages/join-us/join-us.component';
import { AlternativeServiceComponent } from './components/pages/alternative-service/alternative-service.component';
import { RulesComponent } from './components/pages/rules/rules.component';

const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'partners', component: AlternativeServiceComponent },
    { path: 'seller-description', component: SellerDescriptionComponent },
    { path: 'buyer-description', component: BuyerDescriptionComponent },
    { path: 'transporter-description', component: TransporterDescriptionComponent },
    { path: 'blog', component: BlogPageComponent },
    { path: 'join-us', component: JoinUsComponent },
    { path: 'terms-and-conditions', component: RulesComponent },
    { path: 'article/:id', component: BlogDetailsComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }