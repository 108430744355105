<section class="app-download ptb-100">
    <div class="container">
        <div class="app-download-content" *ngIf="false">
            <h3>قم بتنزيل تطبيقاتنا</h3>
            <div class="bar"></div>
            <p>
                قم بزيارة متجر Play لنظام Android أو متجر التطبيقات لـ IOS في هواتفك لتنزيل تطبيقاتنا
            </p>
            <div class="app-holder">
                <a routerLink="/"><img src="assets/img/store/1.png" alt="image"></a>
                <a routerLink="/"><img src="assets/img/store/2.png" alt="image"></a>
            </div>
        </div>
    </div>
</section>