<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><h2> منصة خراج</h2></a>
                    <p class="fs-5">منصة خراج أول منصة من نوعها لتجارة الفواكه والخضار بالجملة صادره عن شركة سعودية / شركة انجم لتقنية المعلومات.</p>
                    <ul class="social-list">
                        <li><a href="https://www.youtube.com/channel/UCHKg5f08j0G2xhMjQQdpySA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://twitter.com/kharaajsa" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.snapchat.com/add/kharaajsa" target="_blank"><i class="fab fa-snapchat"></i></a></li>
                        <li><a href="https://www.instagram.com/kharaajsa/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-1 col-sm-6">
                
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>روابط مفيدة</h3>

                    <ul class="list">
                        <li><a style="cursor:pointer; color: rgb(7, 97, 22); font-size: large; font-weight: bolder;" routerLink="join-us">  
                            انضم إلينا
                        </a></li>

                        <li><a style="cursor:pointer; color: rgb(7, 97, 22); font-size: large; font-weight: bolder;"  (click)="downloadFile()">
                            الشروط والأحكام
                        </a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget" *ngIf="false">
                    <h3>قم بتنزيل تطبيقاتنا</h3>
                    
                    <ul class="footer-holder">
                        <li><a href="#"><img src="assets/img/store/1.png" alt="image"></a></li>
                        <li><a href="#"><img src="assets/img/store/2.png" alt="image"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right">
    <div class="container">
        <div class="copy-right-content">
            <p>
                <i class="far fa-copyright"></i> منصة خراج ٢٠٢٤، جميع الحقوق محفوظة </p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>