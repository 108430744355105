import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transporter-description',
  templateUrl: './transporter-description.component.html',
  styleUrls: ['./transporter-description.component.scss']
})
export class TransporterDescriptionComponent implements OnInit {

  content = {
    title: 'خدماتنا للموصل',
    description: `"كموصلين" تهتم منصتنا "خراج" بكم كرافد داعم في العملية الاقتصادية متمثلة بتخصصكم في سوق النقل واللوجستيات حيث توفر خدمات منصتنا مساندة في توفير عمليات التشغيل بتواجد كم من الطلبات على النقليات المتخصصة في نقل منتوجات الخضار والفواكه سواء المبرد وغير المبرد والتعامل مع السائقين المستقلين أو الشركات والمؤسسات المتخصصة بالنقل ، بحيث نتيح عرضكم لسعر التوصيل حسب رغباتكم بشكل سهل واحترافي سواء داخل منطقتكم أو في أي منطقة من مناطق المملكة مقابل مبلغ رمزي وذلك بموثوقية كبيرة تضمن حقوقكم وتحقق لكم "الحرية الشرائية" بحيث وفرت منصتنا عملية الربط بالطلبات من موقعكم للتوصيل داخل منطقتكم ولجميع مناطق المملكة بآليه تضمن حقوقكم .`,
    pdfLink: 'assets/pdf/موصل pdf.pdf',
    type: 'transporter'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
