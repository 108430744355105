import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  @Input() data: any;
  constructor() { }

  ngOnInit(): void {
  }

  downloadFile(url, type) {
    let link = document.createElement("a");
    link.download = type;
    link.href = url;
    link.click();
  }

}
