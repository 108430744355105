<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/">
                <img height="75px" width="75px" src="assets/img/logo.png">
            </a>
        </div>
        <div class="item d-lg-none d-xl-none" *ngIf="url != '/partners'">
            <img class="package " style="cursor: pointer;" routerLink="partners"
                src="assets/img/package.png" height="30px" width="30px">

            <span class="caption">خدمة طلب كرتون</span>
        </div>


        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li style="cursor: pointer;" data-bs-toggle="collapse" class="nav-item" data-bs-target=".navbar-collapse.show"><a routerLink="/"
                    
                        class="nav-link">الرئيسية</a></li>
                <li style="cursor: pointer;" data-bs-toggle="collapse" class="nav-item" data-bs-target=".navbar-collapse.show"><a    (click)="scrollToSection('about')"
                        class="nav-link">حول
                        منصتنا</a></li>
                <li style="cursor: pointer;" data-bs-toggle="collapse" class="nav-item" data-bs-target=".navbar-collapse.show"><a 
                    (click)="scrollToSection('services')"
                        class="nav-link">خدماتنا</a></li>
                <li style="cursor: pointer;" data-bs-toggle="collapse" class="nav-item" data-bs-target=".navbar-collapse.show"><a 
                    (click)="scrollToSection('faq')"
                        class="nav-link">الأسئلة
                        المتداولة</a></li>
                <li style="cursor: pointer;" data-bs-toggle="collapse" class="nav-item" data-bs-target=".navbar-collapse.show"><a
                        routerLink="blog" class="nav-link">مدونة
                        المنصة</a></li>
                <li style="cursor: pointer;" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" class="nav-item"><a (click)="scrollToSection('contact')"
                        class="nav-link">اتصل
                        بنا</a></li>
            </ul>

            <div  class="item d-none d-sm-block" *ngIf="url != '/partners'">
                <img class="package" style="cursor: pointer;"
                    routerLink="partners" src="assets/img/package.png"
                    height="30px" width="30px">
                <span class="caption">خدمة طلب كرتون</span>
            </div>




            <div *ngIf="url != '/join-us'" class="others-option mx-2">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a data-bs-target=".navbar-collapse.show"  data-bs-toggle="collapse" routerLink="join-us" class="hided default-btn
                            text-white">
                            إنضم إلينا وكن أحد شركاء النجاح
                            <span></span></a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</nav>